import React from 'react'
import {Link} from "react-scroll"
import Typed from 'react-typed'
import { FiPhoneCall } from "react-icons/fi"
import { TfiEmail } from "react-icons/tfi"
import { LanguageHelper } from '../helper/LanguageHelper.tsx'
import { HeroStrings } from '../strings.tsx'


const Hero = ({
        language: language
    }) => {
    
    return (

        <div id='hero-section' className='text-white relative'>

            {/* DESKTOP */}

            <img className='hidden lg:block' src={require('../assets/hero_desktop.png')} alt='Christian Orth' />

            <div className='lg:w-3/5 lg:ml-10 lg:absolute top-12 left-1/3 text-center lg:text-left'>
                
                <h1 className='text-2xl m-5 lg:m-0 2xl:text-4xl'>
                    {LanguageHelper.getLocalizedString(language, HeroStrings.HeroSlogan1DE, HeroStrings.HeroSlogan1EN)} 
                    <span className='text-[#51AACA]'> {LanguageHelper.getLocalizedString(language, HeroStrings.HeroSlogan2DE, HeroStrings.HeroSlogan2EN)} </span>
                    {LanguageHelper.getLocalizedString(language, HeroStrings.HeroSlogan3DE, HeroStrings.HeroSlogan3EN)}
                    <div className='text-[#51AACA] text-3xl 2xl:text-6xl mt-5'>
                        <Typed 
                            strings={[
                                    LanguageHelper.getLocalizedString(language, HeroStrings.HeroSloganTyped1DE, HeroStrings.HeroSloganTyped1EN),
                                    LanguageHelper.getLocalizedString(language, HeroStrings.HeroSloganTyped2DE, HeroStrings.HeroSloganTyped2EN),
                                    LanguageHelper.getLocalizedString(language, HeroStrings.HeroSloganTyped3DE, HeroStrings.HeroSloganTyped3EN),
                                ]}
                            typeSpeed={90}
                            backSpeed={50}
                            loop />
                    </div>
                    
                </h1>


                <img className='lg:hidden mb-5' src={require('../assets/hero_mobile.png')} alt='Christian Orth' />

                <h2 className='text-lg 2xl:text-2xl lg:mx-0 mx-10 my-7'>{LanguageHelper.getLocalizedString(language, HeroStrings.HeroValuePropDE, HeroStrings.HeroValuePropEN)} </h2>
    
                <div className='bg-gray-500 w-100 mx-20 lg:mx-0 lg:w-[250px] rounded-md 2xl:mt-[10px] h-[1px]' />

                <div className='grid lg:grid-cols-2 xl:grid-cols-1'>

                    <div className='mx-auto lg:mx-0'>
                        <div className='flex items-center mt-5 '>
                            <FiPhoneCall color='#51AACA' /> &nbsp; (+49) (0) 176 44 202 108
                        </div>

                        <div className='flex items-center'>
                            <TfiEmail color='#51AACA' /> &nbsp; contact[at]christian-orth.com
                        </div>
                    </div>

                    <div className='mx-auto mt-5 lg:m-0' >
                        <div className='flex space-x-3 xl:mt-7 mt-2 items-center'>
                            <a href='https://scaledagile.com/training/safe-product-owner-product-manager/' target="_blank" rel="noreferrer" className='hover:scale-110'>
                                <img className='2xl:w-[75px] w-[60px]' src={require('../assets/popm.png')} alt='Product Owner and Product Manager Certification' />
                            </a>
                            <a href='https://scaledagile.com/training/leading-safe/' target="_blank" rel="noreferrer" className='hover:scale-110'>
                                <img className='2xl:w-[75px] w-[60px]' src={require('../assets/safe.png')} alt='Leading SAFe Certification' />
                            </a>
                            <a href='https://www.scrum.org/assessments/professional-scrum-master-i-certification' target="_blank" rel="noreferrer"  className='hover:scale-110'>
                                <img className='2xl:w-[75px] w-[60px]' src={require('../assets/psmi.png')} alt='Professional Scrum Master Certification' />
                            </a>
                        </div>
                    </div>

                    
                    <Link to="value-proposition-section" smooth={true} offset={50} duration={500}>
                        <button className='bg-[#51AACA] w-[250px] my-8 rounded-md py-4 mx-auto text-white hover:scale-105'>
                            {LanguageHelper.getLocalizedString(language, HeroStrings.HeroActivationButtonDE, HeroStrings.HeroActivationButtonEN)} 
                        </button>
                    </Link>
                </div>

            </div>

        </div>
    )
}

export default Hero
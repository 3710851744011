import React from 'react'
import { Link } from 'react-router-dom';
import { LanguageHelper } from '../helper/LanguageHelper.tsx';
import { ImprintStrings } from '../strings.tsx';

function Imprint({
  language : language
}) {
  return (
    <div className='text-white max-w-[550px] mx-10 py-10'>

        <Link to="/" className='bg-[#51AACA] w-[500px] rounded-lg py-2.5 p-5 text-white hover:bg-[#344A53]'> &#5130; {LanguageHelper.getLocalizedString(language, ImprintStrings.ImprintButton1DE, ImprintStrings.ImprintButton1EN)} </Link>

        <h1 className='text-5xl mt-12 mb-7 font-bold'> {LanguageHelper.getLocalizedString(language, ImprintStrings.ImprintHeaderDE, ImprintStrings.ImprintHeaderEN)}</h1>

        <h2 className='text-2xl my-4 border-l-[#51AACA] pl-2 border-l-[1px]'>Angaben gemäß § 5 TMG:</h2>
        <div className='pl-2'>
            <p>Christian Orth <br />Freelancer | Einzelunternehmen<br /> IT-Beratung und Mobile App-Entwicklung</p>
            
            <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Postanschrift:</h3>
            <p>Trappentreustr. 17 <br /> 80339 München</p>
            
            <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Kontakt:</h3>
            <p>Telefon: +49 (0) 176 44 202 108 <br /> contact[at]christian-orth.com</p>
            
            <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Vertreten durch:</h3>
            <p>Christian Orth</p>
            
            <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Umsatzsteuer-Identifikationsnummer</h3>
            <p>DE341255787</p>

            <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Die Berufshaftpflichtversicherung wurde abgeschlossen bei:</h3>
            <p>Hiscox SA <br /> Arnulfstraße 31 <br /> 80636 München</p>
        </div>

        <h2 className='text-2xl my-4 border-l-[#51AACA] pl-2 border-l-[1px]'>Hinweise zur Website</h2>
        <div className='pl-2'>
            <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Urheberrechtliche Hinweise</h3>
            <p>Christian Orth</p>
        </div>

        <h2 className='text-2xl my-4 border-l-[#51AACA] pl-2 border-l-[1px]'>Hinweis</h2>
        <div className='pl-2'>
            <p>Die Plattform der EU zur außergerichtlichen Streitbeilegung finden Sie online unter: <a className='border-b-[1px] border-b-[#242222] hover:border-b-white' href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a></p>
            <p className='italic mb-10'>Das Impressum wurde mit dem <a className='border-b-[1px] border-b-[#242222] hover:border-b-white'href="https://www.activemind.de/datenschutz/impressums-generator/">Impressums-Generator der activeMind AG</a> erstellt.</p>
        </div>

        <Link to="/" className='bg-[#51AACA] w-[500px] rounded-lg py-2.5 p-5 text-white hover:bg-[#344A53]'>{LanguageHelper.getLocalizedString(language, ImprintStrings.ImprintButton2DE, ImprintStrings.ImprintButton2EN)}</Link>

    </div>
  );
}

export default Imprint;
import React from 'react'
import { LanguageHelper } from '../helper/LanguageHelper.tsx'
import { TestimonialStrings } from '../strings.tsx'

const SocialProof = ({
    language: language
}) => {
    
    return (
        <div id='social-proof-section' className='w-full px-4 pb-8 text-white border-t-[10px]  border-t-[#90e0ef]'>

            <h1 className='text-center text-5xl lg:pb-[7rem] pb-[3rem] pt-[5rem] font-medium text-[#344A53 leading-tight'>
                {LanguageHelper.getLocalizedString(language, TestimonialStrings.TestimonialHeaderDE, TestimonialStrings.TestimonialHeaderEN)} 
            </h1>

            <div className='max-w-[1240px] mx-auto grid lg:grid-cols-2'>
            
                {/* First column*/}
                <div className='p-4'>
                    <div className='flex'>
                        <p className='text-[#90e0ef] font-serif text-8xl'>"</p>
                        <p className='p-3 m-2 border-[2px] mt-[20px] rounded-lg border-[#90e0ef]'>
                            {LanguageHelper.getLocalizedString(language, TestimonialStrings.TestimonialText1DE, TestimonialStrings.TestimonialText1EN)} 
                        </p>
                    </div>
                    <p className='mt-2 ml-16 mb-5'>
                        <div className='font-bold'>~ tegut... gute Lebensmittel GmbH</div>
                        &nbsp;&nbsp;&nbsp;Rainer Rausch <br />
                        &nbsp;&nbsp;&nbsp;{LanguageHelper.getLocalizedString(language, TestimonialStrings.TestimonialPosition1DE, TestimonialStrings.TestemonnialPosition1EN)} 
                    </p>
                </div>

                {/* Second column*/}
                <div className='p-4 lg:mt-[-50px]'>
                    <div className='flex'>
                        <p className='text-[#90e0ef] font-serif text-8xl'>"</p>
                        <p className='p-3 m-2 border-[2px] mt-[20px] rounded-lg border-[#90e0ef]'>
                            {LanguageHelper.getLocalizedString(language, TestimonialStrings.TestimonialText2DE, TestimonialStrings.TestimonialText2EN)} 
                        </p>
                    </div>
                    <p className='mt-2 ml-16 mb-5'>
                        <div className='font-bold'>~ Kultur & Spielraum e.V.</div>
                        &nbsp;&nbsp;&nbsp;Reinhard Kapfhammer <br />
                        &nbsp;&nbsp;&nbsp;{LanguageHelper.getLocalizedString(language, TestimonialStrings.TestimonialPosition2DE, TestimonialStrings.TestimonialPosition2EN)} 
                    </p>
                </div>

            </div>

            <div className='lg:mx-64 p-4'>
                <div className='flex'>
                            <p className='text-[#90e0ef] font-serif text-8xl'>"</p>
                            <p className='p-3 m-2 border-[2px] mt-[20px] rounded-lg border-[#90e0ef]'>
                                {LanguageHelper.getLocalizedString(language, TestimonialStrings.TestimonialText3DE, TestimonialStrings.TestimonialText3EN)} 
                            </p>
                </div>
                <p className='mt-2 ml-16 mb-5'>
                    <div className='font-bold'>~ Hearbeats Hamburg GmbH</div>
                    &nbsp;&nbsp;&nbsp;Matthias Held <br />
                    &nbsp;&nbsp;&nbsp;{LanguageHelper.getLocalizedString(language, TestimonialStrings.TestimonialPosition3DE, TestimonialStrings.TestimonialPosition3EN)} 
                </p>
            </div>

            <div className='w-full lg:pr-12 pb-8 lg:text-right text-center'> 
                <a href="https://www.freelancermap.de/profil/freelance-it-consultant-agile-product-delivery-und-mobile-apps" target="blank" className='hover:scale-110'>
                        <button className='bg-[#51AACA] w-[250px] rounded-md py-4 mx-auto text-white hover:scale-105'>{LanguageHelper.getLocalizedString(language, TestimonialStrings.TestimonialButtonDE, TestimonialStrings.TestimonialButtonEN)} </button>
                </a>
            </div>

        </div>
    )
}

export default SocialProof
import React from 'react'
import Carousel from './Carousel.tsx'
import { BlogStrings } from '../strings.tsx'
import { LanguageHelper } from '../helper/LanguageHelper.tsx'
import { useState, useEffect } from 'react';

export type Slide = {

    slideID:            number
    slideTitle:         string
    slideImageURL:      string
    slideLinkToMedium:  string

}

const slidesNew = [
   {
        slideID:            1,
        slideTitle:         "Thoughts about updates, rollbacks, and data protection in the context of maintaining mobile apps",
        slideImageURL:      require("../assets/blog_header_1.png"),
        slideLinkToMedium:  "https://medium.com/@christian-orth/thoughts-about-updates-rollbacks-and-data-protection-in-the-context-of-mobile-apps-dda8943a8b66"        
    },
    {
        slideID:            2,
        slideTitle:         "Work in progress - more content coming soon",
        slideImageURL:      require("../assets/blog_header_3.png"),
        slideLinkToMedium:  "https://medium.com/@christian-orth"        
    } 
]

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }

const BlogPreview = ({
        language : language
    }) => {

    const { height, width } = useWindowDimensions();

    return (
        <div id='blog-section' className='bg-[#90E0EF] text-[#344A53] pb-10 border-t-[10px]  border-t-[#344A53]'>
            
            <h1 className='text-center text-5xl p-[3rem] font-bold text-[#344A53]'>{LanguageHelper.getLocalizedString(language, BlogStrings.BlogHeaderDE, BlogStrings.BlogHeaderEN)} </h1>

            <a href="https://medium.com/@christian-orth" target="blank" rel="noreferrer" className='md:flex  justify-center items-center text-center p-[5px] text-xl md:text-2xl mb-10 hover:underline'>
                <div className='md:mx-0 mx-20 md:mt-0 mt-[-40px]'>{LanguageHelper.getLocalizedString(language, BlogStrings.BlogDescriptionDE, BlogStrings.BlogDescriptionEN)}</div>
                <div className='flex mt-[15px] md:mt-0 justify-center'><img className='w-[50px] md:w-[50px] mx-4 hover:scale-105' src={require('../assets/medium.png')} alt='Christan Orth auf Medium' /></div>
            </a>

            <div className='justify-center relative mx-auto pt-[5px] shadow-xl bg-white border-[#51AACA] rounded-lg' style={{ width: width<800 ? width-50 : 800 }}>

                <Carousel autoSlide={false} slideData={slidesNew} language={language} >
                    {slidesNew.map((slide: Slide) => (
                        <div>
                            
                            <p className='h-[125px] text-xl md:text-3xl flex text-center items-center justify-center font-light bg-white px-5' style={{ width: width<800 ? width-50 : 800 }}>
                                {slide.slideTitle} 
                            </p>
                            <img src={slide.slideImageURL} style={{ width: width<800 ? width-50 : 800  }} />
                            

                        </div>
                    ))}
                </Carousel>

            </div>

        </div>
    )
}

export default BlogPreview
import React from 'react'
import { Link } from 'react-router-dom'
import { LanguageHelper } from '../helper/LanguageHelper.tsx';
import { ErrorStrings } from '../strings.tsx';

function NoMatch({
  language : language
}) {
  return (
    <div className='text-white max-w-[1024px] mx-10 py-10'>
      <h1 className='text-4xl mb-10'>
          {LanguageHelper.getLocalizedString(language, ErrorStrings.NoMatchHeaderDE, ErrorStrings.NoMatchHeaderEN)}
      </h1>
      <Link to="/" className='bg-[#51AACA] w-[500px] rounded-lg py-2.5 p-5 text-white hover:bg-[#344A53]'> 
        &#5130; {LanguageHelper.getLocalizedString(language, ErrorStrings.NoMatchButtonDE, ErrorStrings.NoMatchButtonEN)}  
      </Link>
    </div>
  );
}

export default NoMatch;
import React from 'react';
import {useEffect, useState} from 'react'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { LanguageHelper } from '../helper/LanguageHelper.tsx';
import { BlogStrings } from '../strings.tsx';

const Carousel = ({
    children: slides,
    autoSlide = false,
    autoSlideInterval = 3000,
    slideData: slideData,
    language: language
    }) => {

        const [curr, setCurr] = useState(0)

        const prev = () => setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1))
        const next = () => setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1))

        useEffect(() => {
            if (!autoSlide) return
            const slideInterval = setInterval(next, autoSlideInterval)
            return () => clearInterval(slideInterval)
        }, [])  

        const getLinkForArticle = () : string => {
            console.log(slideData[1].slideLinkToMedium)
            return slideData[curr].slideLinkToMedium
        }

        return (
            <div className="relative overflow-hidden bg-[#90e0ef]">

                {/* SLIDES */}

                <div
                    className="flex transition-transform ease-out duration-500"
                    style={{ transform: `translateX(-${curr * 100}%)` }}>
                       
                        {slides} 

                </div>
                

                {/* BUTTONS */}

                <div className="absolute inset-0 flex items-center justify-between p-2 mt-[75px]">
                    <button onClick={prev} className='p-1 rounded-full shadow text-white hover:bg-[#51AACA]'>
                        <FaChevronLeft size={50} />
                    </button>
                    <button onClick={next} className='p-1 rounded-full shadow text-white hover:bg-[#51AACA]'>
                        <FaChevronRight size={50} />
                    </button>
                </div>

                <a href={getLinkForArticle()} target="blank" rel="noreferrer" className="absolute top-[130px] right-2">
                    <div className='flex items-center px-2 md:px-3 py-1 md:py-2 rounded-md shadow md:text-xl bg-[#51AACA] hover:scale-105'>
                        <span className='text-white'>{LanguageHelper.getLocalizedString(language, BlogStrings.BlogButtonDE, BlogStrings.BlogButtonEN)} </span>
                        <img className='w-[20px] md:w-[35px] ml-2' src={require('../assets/medium.png')} alt='Christan Orth auf Medium' />  
                    </div>
                </a>


                {/* INDICATORS */}

                <div className="absolute bottom-4 right-0 left-0">
                    <div className="flex items-center justify-center gap-2">
                        {slides.map((_, i) => (
                            <div className={`transition-all w-3 h-3 bg-white rounded-full ${curr === i ? "p-2" : "bg-opacity-50"}`}/>
                        ))}
                    </div>
                </div>
            </div>
        )
}

export default Carousel
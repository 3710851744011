import React from 'react'
import { FaCheckSquare } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa6";
import { PiToolboxDuotone } from "react-icons/pi";
import { BsQuestionSquare } from "react-icons/bs";
import { IconContext } from "react-icons";
import { Link } from "react-scroll"
import { ValuePropositionStrings } from '../strings.tsx';
import { LanguageHelper } from '../helper/LanguageHelper.tsx';

const ValueProposition = ({
        language : language
    }) => {
    
    return (
        <div id='value-proposition-section' className='w-full px-4 pb-20 bg-[#EFFBFF] text-[#344A53] border-t-[10px]  border-t-[#90e0ef]'>

            <h1 className='text-center text-5xl pt-[5rem] font-medium text-[#344A53] leading-tight'>
                {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPHeaderDE, ValuePropositionStrings.VPHeaderEN)}
            </h1>
            
            <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3 '>

            {
                // PROBLEMS I SOLVE
            }

                <div className='border shadow-xl bg-white flex flex-col mt-[7rem] p-4 m-4 rounded-lg hover:border-[#51AACA] hover:scale-105 duration-300'>
                
                    <IconContext.Provider value={{ color: "#97AFBA", size:"75", className: "mx-auto p-3 mt-[-3.5rem] rounded-lg bg-[#EFFBFF]" }}>
                        <div>
                            <BsQuestionSquare />
                        </div>
                    </IconContext.Provider>

                    <h1 className="text-lg font-bold text-center text-[#97AFBA] pt-5 pb-5">
                        {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPTitle1DE, ValuePropositionStrings.VPTitle1EN)}
                    </h1>
                    <p className='text-center text-4xl pt-2 pb-4 font-medium border-b'>
                        {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPSubTitle1DE, ValuePropositionStrings.VPSubTitle1EN)}
                    </p>
                    <div>
                        <p className='py-8 text-base text-center border-b mx-8'>
                            {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPDescription1DE, ValuePropositionStrings.VPDescription1EN)}
                        </p>
                        <div className='py-4 ml-12 border-b space-y-2 text-base'>
                            <div className='flex items-center'>
                                <FaCheckSquare size={25} color='#97AFBA' /> &nbsp; {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPCard1Bullet1DE, ValuePropositionStrings.VPCard1Bullet1EN)}
                            </div>
                            <div className='flex items-center'>
                                <FaCheckSquare size={25} color='#97AFBA' /> &nbsp; {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPCard1Bullet2DE, ValuePropositionStrings.VPCard1Bullet2EN)}
                            </div>
                            <div className='flex items-center'>
                                <FaCheckSquare size={25} color='#97AFBA' /> &nbsp; {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPCard1Bullet3DE, ValuePropositionStrings.VPCard1Bullet3EN)}
                            </div>
                        </div>
                    </div>
                    
                    <Link to="hero-section" spy={true} smooth={true} offset={-100} duration={500} className='bg-[#51AACA] w-[250px] text-lg text-center rounded-md my-10 py-4 mx-auto text-white hover:scale-105 cursor-pointer'>
                        {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPActivationButtonDE, ValuePropositionStrings.VPActivationButtonEN)}
                    </Link>
                </div>

            {
                // TOOLSET I BRING
            }

                <div className='border shadow-xl bg-white flex flex-col mt-[7rem] p-4 m-4 rounded-lg hover:border-[#51AACA] hover:scale-105 duration-300'>
                
                    <IconContext.Provider value={{ color: "#97AFBA", size:"75", className: "mx-auto p-1 mt-[-3.5rem] rounded-lg bg-[#EFFBFF]" }}>
                        <div>
                            <PiToolboxDuotone />
                        </div>
                    </IconContext.Provider>

                    <h1 className="text-lg font-bold text-center text-[#97AFBA] pt-5 pb-5">
                        {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPTitle2DE, ValuePropositionStrings.VPTitle2EN)}
                    </h1>
                    <p className='text-center text-4xl pt-2 pb-4 font-medium border-b'>
                        {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPSubTitle2DE, ValuePropositionStrings.VPSubTitle2EN)}
                    </p>
                    <div>
                        <p className='py-8 text-base text-center border-b mx-8'>
                            {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPDescription2DE, ValuePropositionStrings.VPDescription2EN)}
                        </p>
                        <div className='py-4 ml-12 border-b space-y-2 text-base'>
                            <div className='flex items-center'>
                                <FaCheckSquare size={25} color='#97AFBA' /> &nbsp; {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPCard2Bullet1DE, ValuePropositionStrings.VPCard2Bullet1EN)}
                            </div>
                            <div className='flex items-center'>
                                <FaCheckSquare size={25} color='#97AFBA' /> &nbsp; {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPCard2Bullet2DE, ValuePropositionStrings.VPCard2Bullet2EN)}
                            </div>
                            <div className='flex items-center'>
                                <FaCheckSquare size={25} color='#97AFBA' /> &nbsp; {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPCard2Bullet3DE, ValuePropositionStrings.VPCard2Bullet3EN)}
                            </div>
                        </div>
                    </div>
                    <Link to="hero-section" spy={true} smooth={true} offset={-100} duration={500} className='bg-[#51AACA] w-[250px] text-lg text-center rounded-md my-10 py-4 mx-auto text-white hover:scale-105 cursor-pointer'>
                        {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPActivationButtonDE, ValuePropositionStrings.VPActivationButtonEN)}
                    </Link>
                </div>


            {
                // APPROACH I OFFER
            }


                <div className='border shadow-xl bg-white flex flex-col mt-[7rem] p-4 m-4 rounded-lg hover:border-[#51AACA] hover:scale-105 duration-300'>
                    
                    <IconContext.Provider value={{ color: "#97AFBA", size:"75", className: "mx-auto p-1 mt-[-3.5rem] rounded-lg bg-[#EFFBFF]" }}>
                        <div>
                            <FaRegHandshake />
                        </div>
                    </IconContext.Provider>
                    <h1 className="text-lg font-bold text-center text-[#97AFBA] pt-5 pb-5">
                        {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPTitle3DE, ValuePropositionStrings.VPTitle3EN)}
                    </h1>
                    <p className='text-center text-4xl pt-2 pb-4 font-medium border-b'>
                        {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPSubTitle3DE, ValuePropositionStrings.VPSubTitle3EN)}
                    </p>
                    <div>
                        <p className='py-8 text-base text-center border-b mx-8'>
                            {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPDescription3DE, ValuePropositionStrings.VPDescription3EN)}
                        </p>
                        <div className='py-4 ml-12 border-b space-y-2 text-base'>
                            <div className='flex items-center'>
                                <FaCheckSquare size={25} color='#97AFBA' /> &nbsp; {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPCard3Bullet1DE, ValuePropositionStrings.VPCard3Bullet1EN)}
                            </div>
                            <div className='flex items-center'>
                                <FaCheckSquare size={25} color='#97AFBA' /> &nbsp; {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPCard3Bullet2DE, ValuePropositionStrings.VPCard3Bullet2EN)}
                            </div>
                            <div className='flex items-center'>
                                <FaCheckSquare size={25} color='#97AFBA' /> &nbsp; {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPCard3Bullet3DE, ValuePropositionStrings.VPCard3Bullet3DE)}
                            </div>
                        </div>
                    </div>
                    <Link to="hero-section" spy={true} smooth={true} offset={-100} duration={500} className='bg-[#51AACA] w-[250px] text-lg text-center rounded-md my-10 py-4 mx-auto text-white hover:scale-105 cursor-pointer'>
                        {LanguageHelper.getLocalizedString(language, ValuePropositionStrings.VPActivationButtonDE, ValuePropositionStrings.VPActivationButtonEN)}
                    </Link>
                </div>

                </div>
            </div>
    )
}

export default ValueProposition
import React , {useState} from 'react'
import {Link} from 'react-scroll'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
import { NavBarStrings } from '../strings.tsx';
import { IconContext } from 'react-icons';
import { LanguageHelper } from '../helper/LanguageHelper.tsx';

const Navbar = ({
        toggleLanguage: toggleLanguage,
        language: language
    }) => {
    
    const [nav, setNav] = useState(true)

    const handleNav = () => {
        setNav(!nav)
    }

    return (
        <div className='flex justify-between items-center h-15 max-w-[1240] mx-auto px-4 py-1 text-white bg-[#51AACA]'>

            {/* DESKTOP */}

            <img className="w-[250px] md:w-[320px] lg:w-[450px]" src={require('../assets/lettering.png')} alt='Schriftzug Christan Orth' />

            <ul className='hidden lg:flex font-medium'>
                <Link to="value-proposition-section" spy={true} smooth={true} offset={50} duration={500} className='cursor-pointer m-4 text-lg border-b-[1px] border-b-[#51AACA] hover:border-b-white'>
                    {LanguageHelper.getLocalizedString(language, NavBarStrings.SkillsDE, NavBarStrings.SkillsEN)}
                </Link>
                <Link to="social-proof-section" spy={true} smooth={true} offset={50} duration={500}  className='cursor-pointer m-4 text-lg border-b-[1px] border-b-[#51AACA] hover:border-b-white'>
                    {LanguageHelper.getLocalizedString(language, NavBarStrings.TestimonialsDE, NavBarStrings.TestimonialsEN)}
                </Link>
                <Link to="blog-section" spy={true} smooth={true} offset={50} duration={500}  className='cursor-pointer m-4 text-lg border-b-[1px] border-b-[#51AACA] hover:border-b-white'>
                    {LanguageHelper.getLocalizedString(language, NavBarStrings.BlogDE, NavBarStrings.BlogEN)}
                </Link>
                { language == "DE"

                    ? <button onClick={toggleLanguage} className='m-4 97AFBA text-lg border-b-[1px] border-b-[#51AACA] hover:border-b-white whitespace-nowrap'><span className=''>DE</span>  <span className='font-thin'>| EN</span></button>
                    : <button onClick={toggleLanguage} className='m-4 97AFBA text-lg border-b-[1px] border-b-[#51AACA] hover:border-b-white whitespace-nowrap'><span className='font-thin'>DE | </span><span className='font-bold'>EN</span></button>
                }
                
            </ul>

            {/* MOBILE */}

            <div onClick={handleNav} className='block lg:hidden'>
                {!nav
                    ? 
                        <IconContext.Provider value={{ size:"30", className: "cursor-pointer" }}>
                            <div>
                                <AiOutlineClose  />
                            </div>
                        </IconContext.Provider>
                    : 
                    
                        <IconContext.Provider value={{ size:"30", className: "cursor-pointer" }}>
                            <div>
                                <AiOutlineMenu />
                            </div>
                        </IconContext.Provider>
                }
            </div>

            <div className= {!nav 
                    ? 'fixed left-0 top-0 w-[75%] h-full border-r border-r-gray-500 bg-[#17181A] ease-in duration-500 z-10'
                    : 'fixed left-[-100%] ease-out duration-500' 
                }>

                <div className='px-4 py-8 font-medium'>

                    <div className='py-4 border-b border-gray-500'>
                        <Link onClick={handleNav} to="hero-section" spy={true} smooth={true} offset={-100} duration={500}  className='cursor-pointer pl-2 text-lg border-l-[1px] border-l-[#17181A] hover:border-l-white'>
                            {LanguageHelper.getLocalizedString(language, NavBarStrings.ContactDE, NavBarStrings.ContactEN)}
                        </Link>
                    </div>

                    <div className='py-4 border-b border-gray-500'>
                        <Link onClick={handleNav} to="value-proposition-section" spy={true} smooth={true} offset={50} duration={500} className='cursor-pointer pl-2 text-lg border-l-[1px] border-l-[#17181A] hover:border-l-white'>
                            {LanguageHelper.getLocalizedString(language, NavBarStrings.SkillsDE, NavBarStrings.SkillsEN)}
                        </Link>
                    </div>

                    <div className='py-4 border-b border-gray-500'>
                        <Link onClick={handleNav}  to="social-proof-section" spy={true} smooth={true} offset={50} duration={500} className='cursor-pointer pl-2 text-lg border-l-[1px] border-l-[#17181A] hover:border-l-white'>
                            {LanguageHelper.getLocalizedString(language, NavBarStrings.TestimonialsDE, NavBarStrings.TestimonialsEN)}                        
                        </Link>
                    </div>


                    <div className='py-4 border-b border-gray-500'>
                        <Link onClick={handleNav} to="blog-section" spy={true} smooth={true} offset={10} duration={500} className='cursor-pointer pl-2 text-lg border-l-[1px] border-l-[#17181A] hover:border-l-white'>
                            {LanguageHelper.getLocalizedString(language, NavBarStrings.BlogDE, NavBarStrings.BlogEN)}
                        </Link>
                    </div>

                    { language == "DE"

                        ? <button onClick={toggleLanguage} className='my-4 97AFBA pl-2 text-lg border-l-[1px] border-l-[#17181A] hover:border-l-white whitespace-nowrap'><span className=''>DE</span>  <span className='font-thin'>| EN</span></button>
                        : <button onClick={toggleLanguage} className='my-4 97AFBA pl-2 text-lg border-l-[1px] border-l-[#17181A] hover:border-l-white whitespace-nowrap'><span className='font-thin'>DE | </span><span className='font-bold'>EN</span></button>
                    }
                </div>

            </div>
        </div>
    )
}

export default Navbar
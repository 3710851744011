import React from 'react'
import { Link } from 'react-router-dom';
import { DataProtectionStrings } from '../strings.tsx';
import { LanguageHelper } from '../helper/LanguageHelper.tsx';

function Privacy({
  language : language
}) {
  return (
    <div className='text-white max-w-[1024px] mx-10 py-10'>

        <Link to="/" className='bg-[#51AACA] w-[500px] rounded-lg py-2.5 p-5 text-white hover:bg-[#344A53]'> &#5130; {LanguageHelper.getLocalizedString(language, DataProtectionStrings.DataProtectionButton1DE, DataProtectionStrings.DataProtectionButton1EN)} </Link>

        <h1 className='text-5xl mt-12 mb-7 font-bold'> {LanguageHelper.getLocalizedString(language, DataProtectionStrings.DataProtectionHeaderDE, DataProtectionStrings.DataProtectionHeaderEN)}</h1>

        <p className='mt-10 pl-2'>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
        <p className='pl-2 font-bold'>Christian Orth</p>

        <h2 className='text-2xl my-4 border-l-[#51AACA] pl-2 border-l-[1px]'>Ihre Betroffenenrechte</h2>
        <div className='pl-2'>
          <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
          <ul>
            <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
            <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
            <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
            <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
            <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
            <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
          </ul>
          <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
          <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.</p>
          <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a className='border-b-[1px] border-b-[#242222] hover:border-b-white' href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html" target="_blank" rel="noopener nofollow noreferrer">https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a>.</p>
      </div>
      
      <h2 className='text-2xl my-4 border-l-[#51AACA] pl-2 border-l-[1px]'>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
      <div className='pl-2'>      
          <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Art und Zweck der Verarbeitung:</h3>
          <p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches. </p>
          <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
          <ul>
            <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
            <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
            <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
            <li>zur Optimierung unserer Website.</li>
          </ul>
          <p>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren. </p>

          <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Rechtsgrundlage und berechtigtes Interesse:</h3>
          <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.</p>

          <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Empfänger:</h3>
          <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig werden.</p>

          <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Drittlandtransfer:</h3>
          <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen: </p>
          <p>Nein</p>
          <p>Folgende Datenschutzgarantien liegen vor: </p>

          <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Speicherdauer:</h3>
          <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet ist. </p>

          <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Bereitstellung vorgeschrieben oder erforderlich:</h3>
          <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen. </p>
      </div>

      <h2 className='text-2xl my-4 border-l-[#51AACA] pl-2 border-l-[1px]'>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
          <div className='pl-2'>

          <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Einzelfallbezogenes Widerspruchsrecht</h3>
          <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
          <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>

          <h3 className='text-xl my-2 py-1 border-b-white border-b-[0.5px]'>Empfänger eines Widerspruchs</h3>
          <p>Christian Orth</p>
      </div>

      <h2 className='text-2xl my-4 border-l-[#51AACA] pl-2 border-l-[1px]'>Änderung unserer Datenschutzbestimmungen</h2>
      <div className='pl-2'>
          <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
      </div>
      
      <h2 className='text-2xl my-4 border-l-[#51AACA] pl-2 border-l-[1px]'>Fragen an den Datenschutzbeauftragten</h2>
      <div className='pl-2'>
        <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:</p>

        <p className='italic mb-10'> Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a  className='border-b-[1px] border-b-[#242222] hover:border-b-white' href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank" rel="noreferrer noopener">externe Datenschutzbeauftragte</a> (Version #2020-09-30).</p>
      </div>

      <Link to="/" className='bg-[#51AACA] w-[800px] rounded-lg py-2.5 px-5 text-white hover:bg-[#344A53]'>{LanguageHelper.getLocalizedString(language, DataProtectionStrings.DataProtectionButton2DE, DataProtectionStrings.DataProtectionButton2EN)}</Link>

    </div>
  );
}

export default Privacy;
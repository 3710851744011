import React , {useState} from 'react'
import {Link} from 'react-scroll'
import { NavBarStrings } from '../strings.tsx'
import { LanguageHelper } from '../helper/LanguageHelper.tsx'

const NavbarBottom = ({
        toggleLanguage: toggleLanguage,
        language: language
    }) => {
    
    const [nav, setNav] = useState(true)

    const handleNav = () => {
        setNav(!nav)
    }

    return (
        <div className='flex justify-between items-center h-35 max-w-[1240] mx-auto px-4 text-[#2760A3] bg-white'>

            <Link to="hero-section" spy={true} smooth={true} offset={-100} duration={500} className="cursor-pointer" >
                <img className="w-[250px] md:w-[320px] lg:w-[350px] hover:scale-105" src={require('../assets/logo_big.png')} alt='Schriftzug Christan Orth' />
            </Link>
            
            <ul className='hidden lg:flex font-medium'>
                <Link to="hero-section" spy={true} smooth={true} offset={-100} duration={500} className='cursor-pointer m-4 text-lg border-b-[1px] hover:border-b-[#2760A3] border-b-white'>
                    {LanguageHelper.getLocalizedString(language, NavBarStrings.ContactDE, NavBarStrings.ContactEN)}
                </Link>
                <Link to="value-proposition-section" spy={true} smooth={true} offset={50} duration={500} className='cursor-pointer m-4 text-lg border-b-[1px] hover:border-b-[#2760A3] border-b-white'>
                    {LanguageHelper.getLocalizedString(language, NavBarStrings.SkillsDE, NavBarStrings.SkillsEN)}
                </Link>
                <Link to="social-proof-section" spy={true} smooth={true} offset={50} duration={500}  className='cursor-pointer m-4 text-lg border-b-[1px] hover:border-b-[#2760A3] border-b-white'>
                    {LanguageHelper.getLocalizedString(language, NavBarStrings.TestimonialsDE, NavBarStrings.TestimonialsEN)}
                </Link>
                <Link to="blog-section" spy={true} smooth={true} offset={50} duration={500} className='cursor-pointer m-4 text-lg border-b-[1px] hover:border-b-[#2760A3] border-b-white'>
                    {LanguageHelper.getLocalizedString(language, NavBarStrings.BlogDE, NavBarStrings.BlogEN)}
                </Link>
                { language == "DE"

                    ? <button onClick={toggleLanguage} className='m-4 97AFBA text-lg border-b-[1px] hover:border-b-[#51AACA] border-b-white whitespace-nowrap'><span className=''>DE</span>  <span className='font-thin'>| EN</span></button>
                    : <button onClick={toggleLanguage} className='m-4 97AFBA text-lg border-b-[1px] hover:border-b-[#51AACA] border-b-white whitespace-nowrap'><span className='font-thin'>DE | </span><span className='font-bold'>EN</span></button>
                }
            </ul>


            <div onClick={handleNav} className='block lg:hidden hover:scale-125'>
                <Link to="hero-section" spy={true} smooth={true} offset={-100} duration={500} className="cursor-pointer text-[#2760A3] text-3xl" >
                     &#9650;
                </Link>
            </div>

            
        </div>
    )
}

export default NavbarBottom
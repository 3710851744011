
export enum NavBarStrings {

    SkillsEN = "SKILLSET",
    SkillsDE = "KOMPETENZEN",

    PortfolioEN = "PORTFOLIO",
    PortfolioDE = PortfolioEN,

    BlogEN = "BLOG",
    BlogDE = BlogEN,

    ContactEN = "CONTACT",
    ContactDE = "KONTAKT",

    TestimonialsEN = "TESTIMONIALS",
    TestimonialsDE = "REFERENZEN"

}

export enum HeroStrings {

    
    HeroSlogan1DE = "Auf der Suche nach",
    HeroSlogan2DE = "einem Freelancer",
    HeroSlogan3DE = "der sich auskennt mit:",

    HeroSloganTyped1DE = " Agilen Methoden ?",
    HeroSloganTyped2DE = " Digitalen Produkten ?",
    HeroSloganTyped3DE = " Mobilen Apps ?",

    HeroSlogan1EN = "Looking for",
    HeroSlogan2EN = "a Freelancer",
    HeroSlogan3EN = "who knows how to:",

    HeroSloganTyped1EN = " Apply Agile Methods ?",
    HeroSloganTyped2EN = " Realize Digital Products ?",
    HeroSloganTyped3EN = " Implement Mobile Apps ?",

    HeroValuePropDE = "Als Produkt-Mensch mit technischen Skills und Feingefühl für fesselnde UI/UX, helfe ich Ihnen Ihr digitales Projektvorhaben zu einem Erfolg zu machen! ",

    HeroValuePropEN = "As a product person with a technical background and a feeling for engaging UI/UX, I support businesses on their journey toward outstanding Digital Products!",

    HeroActivationButtonDE = "MEHR ERFAHREN",
    HeroActivationButtonEN = "FIND OUT MORE",

    //HeroActivatorEN = "You are a business owner who knows that Mobile Apps are crucial for your customer relationship? You want to launch a Mobile Experience or bring your Mobile App to the next level?",

}

export enum ValuePropositionStrings {

    VPHeaderEN = "Working together for digital success",
    VPHeaderDE = "Gemeinsam zum digitalen Erfolg",

    // CART 1 - PROBLEMS I SOLVE

    VPTitle1EN = "The Challenge",
    VPTitle1DE = "Herausforderung",

    VPSubTitle1EN = "Problems with staffing?",
    VPSubTitle1DE = "Probleme beim Staffing?",

    VPDescription1EN = "Good staff are hard to find, and specialist knowledge is sometimes only required in the medium term. I can help you overcome bottlenecks for your digital project. As a specialist or interim support.",
    VPDescription1DE = "Gutes Personal ist schwer zu finden und Spezialwissen manchmal auch nur mittelfristig erforderlich. Ich helfe Ihnen dabei, Engpässe für Ihr Digital-Projekt zu überwinden. Als Spezialist oder Interim-Support.",

    VPCard1Bullet1EN = "Product Owner",
    VPCard1Bullet1DE = VPCard1Bullet1EN,

    VPCard1Bullet2EN = "Agile Business Analyst",
    VPCard1Bullet2DE = VPCard1Bullet2EN,

    VPCard1Bullet3EN = "Mobile Applications",
    VPCard1Bullet3DE = "Mobile Applikationen",

    // CART 2 - TOOLSET I HAVE

    VPTitle2EN = "The Toolset",
    VPTitle2DE = "Werkzeugkoffer",

    VPSubTitle2EN = "Agile methods with a hands-on mentality",
    VPSubTitle2DE = "Agile Methoden mit Hands-on-Mentalität",

    VPDescription2EN = "Quickly test hypotheses and iteratively bring features to market on time? While keeping the customer, stakeholders, and KPIs in focus? Whether you're a startup or a corporation - let's get started!",
    VPDescription2DE = "Hypothesen schnell überprüfen und Features iterativ zeitnah an den Markt bringen? Dabei Kunde, Stakeholder und KPIs im Fokus behalten? Egal ob Startup oder Konzern - lassen Sie uns loslegen!",

    VPCard2Bullet1EN = "10 years of project experience",
    VPCard2Bullet1DE = "10 Jahre Projekterfahrung",

    VPCard2Bullet2EN = "Versatile methodological skills",
    VPCard2Bullet2DE = "Vielseitige Methodenkompetenz",

    VPCard2Bullet3EN = "Technical know-how",
    VPCard2Bullet3DE = "Technisches Know-How",

    // CART 3 - APPROACH I SUGGEST

    VPTitle3EN = "The Approach",
    VPTitle3DE = "Kooperation",

    VPSubTitle3EN = "Reliable team player",
    VPSubTitle3DE = "Zuverlässiger Teamplayer",

    VPDescription3EN = "In the end, it's the daily interaction with our fellow human beings that makes the difference. Do you value respectful and friendly cooperation? You can rely on me and my results.",
    VPDescription3DE = "Am Ende macht die tägliche Interaktion mit unseren Mitmenschen den Unterschied. Sie legen Wert auf eine respektvolle und sympathische Zusammenarbeit? Auf mich und meine Ergebnisse können Sie sich verlassen.",

    VPCard3Bullet1EN = "Enjoy working in a team",
    VPCard3Bullet1DE = "Spaß am Arbeiten im Team",

    VPCard3Bullet2EN = "Transparent and reliable",
    VPCard3Bullet2DE = "Transparent und Zuverlässig",

    VPCard3Bullet3EN = "Learning enthusiast",
    VPCard3Bullet3DE = "Bereit Neues zu lernen",

    // BUTTON

    VPActivationButtonDE = "KONTAKT AUFNEHMEN",
    VPActivationButtonEN = "CONTACT ME",

}

export enum TestimonialStrings {

    TestimonialHeaderDE = "Das sagen Kunden und Partner 🚀🚀",
    TestimonialHeaderEN = "What customers and partners say 🚀🚀",

    TestimonialText1DE = "Christian hat als Product Owner für die neu eingeführte tebonus App innerhalb des tegut... Kundenbindungsprogramms sehr erfolgreich dazu beigetragen, dass die App-Konzeption umgesetzt und weiterentwickelt wurde. Durch seine sehr strukturierte Planung und Abstimmung mit dem Entwicklerteam ist es ihm gelungen, in den Sprints alle notwendigen Aufgaben platziert und erledigt zu bekommen. Wir sind ihm für seine kompetente Unterstützung im Projekt sehr dankbar.",
    TestimonialText1EN = "As Product Owner for the newly introduced tebonus app within the tegut... customer loyalty program, Christian contributed very successfully to implementing and further developing the app concept. Thanks to his very structured planning and coordination with the development team, he succeeded in getting all the necessary tasks placed and completed in the sprints. We are very grateful to him for his competent support in the project.",

    TestimonialPosition1DE = "Bereichlseiter Marketing",
    TestemonnialPosition1EN = "Head of Marketing",

    TestimonialText2DE = "Wir schätzen uns sehr glücklich, Christian als technischen Ansprechpartner und Entwickler für unsere Mobile App im Team zu haben. Er hat ausschlaggebend dabei geholfen, unsere Ideen in eine tolle App zu übersetzen und begleitet uns seit der Konzeptionsphase. Es macht Spaß, mit Christian zusammenzuarbeiten - er kommuniziert transparent, ist für neue Ideen zu begeistern und ein sehr zuverlässiger Partner!",
    TestimonialText2EN = "We consider ourselves very lucky to have Christian on our team as a technical contact and developer for our mobile app. He was instrumental in translating our ideas into a great app and has been with us since the conception phase. Christian is a pleasure to work with - he communicates transparently, is enthusiastic about new ideas, and is a very reliable partner!",

    TestimonialPosition2DE = "Herausgeber",
    TestimonialPosition2EN = "Publisher",

    TestimonialText3DE = "Im Rahmen der Entwicklung einer Web und Mobile App, waren wir als UX/UI Team für ein Jahr mit Christian als Product Owner in einem agilen Projekt. Die Zusammenarbeit lief hervorragend: Saubere Briefings, klare Aufgabendefinitionen, Priorisierung, Timings, Prozesse und Sparring. Ob bei der Bewertung technischer Umsetzbarkeiten, beim Einholen von Stakeholder-Feedback oder bei kurzem Austausch zur Ideenbewertung. Wir freuen uns jetzt schon auf das nächste gemeinsame Projekt mit ihm!",
    TestimonialText3EN = "As part of developing a web and mobile app, we worked as a UX/UI team for a year, with Christian as product owner on an agile project. The collaboration was excellent: clean briefings, clear task definitions, prioritization, timings, processes, and sparring. Whether assessing technical feasibility, obtaining stakeholder feedback, or brief exchanges to evaluate ideas. We are already looking forward to our next project with him!",

    TestimonialPosition3DE = "Geschäftsführer",
    TestimonialPosition3EN = "Chief Executive Officer",

    TestimonialButtonDE = "WEITERE REFERENZEN",
    TestimonialButtonEN = "MORE TESTIMONIALS",

}

export enum BlogStrings {

    BlogHeaderDE = "Inhalte aus meinem Blog",
    BlogHeaderEN = "Contents from my Blog",
    
    BlogDescriptionDE = 'Ich schreibe meine Artikel in englischer Sprache auf der Plattform "Medium"',
    BlogDescriptionEN = 'I write my articles on the platform "Medium"',

    BlogButtonDE = "Auf Medium lesen",
    BlogButtonEN = "Read on Medium"
    
}

export enum FooterStrings {

    FooterImprintDE = "Impressum",
    FooterImprintEN = "Imprint",
    
    FooterDataDE = "Datenschutz",    
    FooterDateEN= "Data Privacy"
}

export enum ImprintStrings {

    ImprintHeaderDE = "Impressum",
    ImprintHeaderEN = "Imprint",

    ImprintButton1DE = "Zurück",
    ImprintButton1EN = "Back",

    ImprintButton2DE = "Impressum verlassen",
    ImprintButton2EN = "Leave imprint",
    
    ImprintTitle1DE = "Angaben gemäß § 5 TMG:",
    ImprintTitle1EN = "Information according to § 5 TMG:",

    ImprintJob1DE = "Freelancer | Einzelunternehmen",
    ImprintJob1EN = "Freelancer | Sole proprietorship",

    ImprintJob2DE = "IT-Beratung und Mobile App-Entwicklung",
    ImprintJob2EN = "IT consulting and mobile app development",

    ImprintTitle2DE = "Hinweise zur Website",
    ImprintTitle2EN = "Notes on the website",


    ImprintTitle3DE = "Hinweis",
    ImprintTitle3EN = "Notice",

    ImprintFinalNoteDE = "Die Plattform der EU zur außergerichtlichen Streitbeilegung finden Sie online unter: https://ec.europa.eu/consumers/odr/ Das Impressum wurde mit dem Impressums-Generator der activeMind AG erstellt.",
    ImprintFinalNoteEN = "You can find the EU platform for out-of-court dispute resolution online at: https://ec.europa.eu/consumers/odr/ The imprint was created with the imprint generator of activeMind AG."
}

export enum DataProtectionStrings {

    DataProtectionHeaderDE = "Datenschutz",
    DataProtectionHeaderEN = "Data Privacy",

    DataProtectionButton1DE = "Zurück",
    DataProtectionButton1EN = "Back",

    DataProtectionButton2DE = "Datenschutz verlassen",
    DataProtectionButton2EN = "Leave Data Privacy",
}

export enum ErrorStrings {

    NoMatchHeaderDE = "404: Seite nicht gefunden.",
    NoMatchHeaderEN = "404: Page not found.",
    
    NoMatchButtonDE = "Zur Startseite",    
    NoMatchButtonEN = "Go to hompage"
}



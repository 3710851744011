import React from 'react'
import Navbar from '../components/Navbar.tsx';
import Hero from '../components/Hero.tsx';
import BlogPreview from '../components/BlogPreview.tsx';
import SocialProof from '../components/Socialproof.tsx';
import NavbarBottom from '../components/NavbarBottom.tsx';
import Footer from '../components/Footer.tsx';
import ValueProposition from '../components/ValueProposition.tsx';

function Home({
  toggleLanguage : toggleLanguage,
  language : language
}) {
  return (
    <div>
      <Navbar toggleLanguage={toggleLanguage} language={language} />
      <Hero language={language} />
      <ValueProposition language={language}  />
      <SocialProof language={language}  /> 
      <BlogPreview language={language} /> 
      <NavbarBottom toggleLanguage={toggleLanguage} language={language}/>
      <Footer language={language} />
    </div>
  );
}

export default Home;
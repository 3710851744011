
export class LanguageHelper {

    static getLocalizedString(language: string, idDE:string, idEN: string):string {
        
        if (language == "DE") {
            return idDE
        }

        else return  idEN

    }
}
import React from 'react'
import {Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './pages/Home.tsx';
import NoMatch from './pages/NoMatch.tsx';
import Privacy from './pages/Privacy.tsx';
import Imprint from './pages/Imprint.tsx';
import ScrollToTop from './helper/ScrollToTop.tsx';
import { useState } from 'react';

function App() {

  const [language, setActiveLanguage] = useState("DE")

  const toggleLanguage = () => {
    if(language === "DE"){
      setActiveLanguage("EN")
    } 
    else {
      setActiveLanguage("DE")
    }
  }

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home toggleLanguage = {toggleLanguage} language = {language} />} />
          <Route path="/imprint" element={<Imprint language = {language}  />} />
          <Route path="/privacy" element={<Privacy language = {language}  />} />
          <Route path="*" element={<NoMatch language = {language}  />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
